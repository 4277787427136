import { TFunction } from "i18next";
import z from "zod";

import { DateZod } from "../../../models/primitives";
import { ConvertValuesOptionZod } from "../../Analyzer/api/analysis";
import { getLeafPartitions } from "../../PrognosAI/functions/partition";
import { SolutionDetail } from "../../PrognosAI/models/solution";

const GenericBlockZod = z.object({
  blockId: z.number(),
  label: z.string(),
  description: z.string().optional(),
  x: z.number(),
  y: z.number(),
});
export type GenericBlock = z.infer<typeof GenericBlockZod>;

export const aggregationFns = ["Min", "Mean", "Max", "Percentile"] as const;
const AggregationFnZod = z.enum(aggregationFns);
export type AggregationFn = z.infer<typeof AggregationFnZod>;

export const SourceParametersZod = z.object({
  measurementIds: z.number().array(),
  diffPartitionerIds: z.number().array(),
  // later: discriminated union based on the map input type
  inputProperties: z.object({
    // source planning areas
    partitionIds: z.number().array(),
    convertValues: ConvertValuesOptionZod,
    fromDateArg: DateZod.nullable(),
    fromDateVar: z.number().nullable(),
    toDateArg: DateZod.nullable(),
    toDateVar: z.number().nullable(),
    minValueArg: z.number().nullable(),
    minValueVar: z.number().nullable(),
    maxValueArg: z.number().nullable(),
    maxValueVar: z.number().nullable(),
  }),
  // later: discriminated union based on the map data shape type
  shapeProperties: z.object({
    aggregationFn: AggregationFnZod,
    aggregationFnArg: z.number().nullable(),
    aggregationFnVar: z.number().nullable(),
  }),
});
export type SourceParameters = z.infer<typeof SourceParametersZod>;

export const transformationMethods = [
  "Add",
  "Subtract",
  "Multiply",
  "Divide",
  "Min",
  "Max",
  "RoundUp",
  "RoundDown",
] as const;
export const TransformationMethodZod = z.enum(transformationMethods);
export type TransformationMethod = z.infer<typeof TransformationMethodZod>;

export const TransformationParametersZod = z.object({
  methodFn: TransformationMethodZod,
  methodFnArg: z.number().nullable(),
  methodFnVar: z.number().nullable(),
});
export type TransformationParameters = z.infer<
  typeof TransformationParametersZod
>;

export const AggregationInputZod = z.object({
  aggregationInputId: z.number(),
  label: z.string(),
});
export type AggregationInput = z.infer<typeof AggregationInputZod>;

export const aggregationMethods = ["Sum", "Min", "Max"] as const;
export const AggregationMethodZod = z.enum(aggregationMethods);
export type AggregationMethod = z.infer<typeof AggregationMethodZod>;

export const AggregationParametersZod = z.object({
  inputs: AggregationInputZod.array(),
  aggregationMethod: AggregationMethodZod,
  aggregateObservables: z.boolean(),
  observableAggregateName: z.string().optional(),
  aggregatePartitions: z.boolean(),
  partitionAggregateName: z.string().optional(),
});
export type AggregationParameters = z.infer<typeof AggregationParametersZod>;

export const TargetParametersZod = z.object({});
export type TargetParameters = z.infer<typeof TargetParametersZod>;

export const blockTypes = [
  "Source",
  "Transformation",
  "Aggregation",
  "Target",
] as const;
export const BlockTypeZod = z.enum(blockTypes);
export type BlockType = z.infer<typeof BlockTypeZod>;
export const BlockZod = z.discriminatedUnion("type", [
  GenericBlockZod.extend({
    type: z.literal(BlockTypeZod.enum.Source),
    parameters: SourceParametersZod,
  }),
  GenericBlockZod.extend({
    type: z.literal(BlockTypeZod.enum.Transformation),
    parameters: TransformationParametersZod,
  }),
  GenericBlockZod.extend({
    type: z.literal(BlockTypeZod.enum.Aggregation),
    parameters: AggregationParametersZod,
  }),
  GenericBlockZod.extend({
    type: z.literal(BlockTypeZod.enum.Target),
    parameters: TargetParametersZod,
  }),
]);
export type Block = z.infer<typeof BlockZod>;
export type BlockPatchWithId = Partial<Block> & Pick<Block, "blockId">;

export const BlockConnectionZod = z.object({
  blockConnectionId: z.number(),
  fromBlockId: z.number(),
  fromPort: z.number().optional(),
  toBlockId: z.number(),
  toPort: z.number().optional(),
});
export type BlockConnection = z.infer<typeof BlockConnectionZod>;

export function getDefaultTypeParams(
  type: BlockType,
  solution: SolutionDetail | undefined,
  t: TFunction
) {
  switch (type) {
    case "Source":
      return getDefaultSourceBlock(solution);
    case "Transformation":
      return getDefaultTransformationBlock();
    case "Aggregation":
      return getDefaultAggregationBlock(t);
    case "Target":
      return getDefaultTargetBlock();
  }
}

function getDefaultSourceBlock(solution: SolutionDetail | undefined): {
  type: "Source";
  parameters: SourceParameters;
} {
  const {
    measurements = [],
    partitions = [],
    partitioners = [],
  } = solution ?? {};
  const measurementIds = measurements.map((m) => m.measurementId);
  const partitionIds = getLeafPartitions(partitions, partitioners).map(
    (p) => p.partitionId
  );

  const diffPartitionerIds =
    solution?.partitioners.map((p) => p.partitionerId) ?? [];

  return {
    type: "Source",
    parameters: {
      measurementIds,
      diffPartitionerIds,
      inputProperties: {
        partitionIds,
        convertValues: "None",
        fromDateArg: null,
        fromDateVar: null,
        toDateArg: null,
        toDateVar: null,
        minValueArg: null,
        minValueVar: null,
        maxValueArg: null,
        maxValueVar: null,
      },
      shapeProperties: {
        aggregationFn: "Mean",
        aggregationFnArg: null,
        aggregationFnVar: null,
      },
    },
  };
}

function getDefaultTransformationBlock(): {
  type: "Transformation";
  parameters: TransformationParameters;
} {
  return {
    type: "Transformation",
    parameters: {
      methodFn: "Add",
      methodFnArg: 0,
      methodFnVar: null,
    },
  };
}

function getDefaultAggregationBlock(t: TFunction): {
  type: "Aggregation";
  parameters: AggregationParameters;
} {
  return {
    type: "Aggregation",
    parameters: {
      inputs: [
        {
          aggregationInputId: 1,
          label: t("{{count}}th input", { count: 1, ordinal: true }),
        },
        {
          aggregationInputId: 2,
          label: t("{{count}}th input", { count: 2, ordinal: true }),
        },
      ],
      aggregationMethod: "Sum",
      aggregateObservables: false,
      aggregatePartitions: false,
    },
  };
}

function getDefaultTargetBlock(): {
  type: "Target";
  parameters: TargetParameters;
} {
  return {
    type: "Target",
    parameters: {},
  };
}

import React, { type JSX } from "react";

import DebouncedWrapper from "../DebouncedWrapper";
import TextField, { TextFieldProps } from "./TextField";

interface DebouncedTextFieldProps extends Omit<TextFieldProps, "onChange"> {
  delay?: number;
  onChange(value: string): void;
  generateError?(value: TextFieldProps["value"]): string | boolean | undefined;
}

const DebouncedTextField = React.forwardRef<
  HTMLInputElement,
  DebouncedTextFieldProps
>((props, ref): JSX.Element => {
  const { value, onChange, generateError, error, delay = 300, ...rest } = props;

  return (
    <DebouncedWrapper
      delay={delay}
      value={value}
      onChange={onChange}
      generateError={generateError}
    >
      {({ value: innerValue, onChange: handleChange, error: innerError }) => (
        <TextField
          {...rest}
          ref={ref}
          value={innerValue}
          onChange={(e) => handleChange(e.target.value)}
          error={error ?? innerError}
        />
      )}
    </DebouncedWrapper>
  );
});

export default DebouncedTextField;

import React, { type JSX } from "react";
import { useTranslation } from "react-i18next";
import { LuChartLine } from "react-icons/lu";
import { LuChartBar } from "react-icons/lu";
import { useLocation, useParams } from "react-router";

import {
  Cog6ToothIcon,
  HomeIcon,
  TableCellsIcon,
} from "@heroicons/react/24/outline";

import { useSolution } from "../../../../api/solutions";
import Sidebar, {
  CustomIcon,
  SidebarItem,
} from "../../../../components/sidebar/Sidebar";
import BasicSkeleton from "../../../../components/skeletons/BasicSkeleton";
import ListPageSkeleton from "../../../../components/skeletons/ListPageSkeleton";
import SettingsSkeleton from "../../../../components/skeletons/SettingsSkeleton";
import { compareScales, getMinScale } from "../../../../models/aggregate";
import BasicLayout from "../../../PrognosAI/components/layouts/BasicLayout";
import { getAnalysisPath, getAnalyzerPath } from "../../routes/analyzer";
import { getDailyProfilesPath } from "../../routes/dailyProfiles";
import { getOverviewPath } from "../../routes/overview";
import { getSettingsPath } from "../../routes/settings";
import { getTimeSeriesPath } from "../../routes/timeSeries";
import { getWeeklyProfilesPath } from "../../routes/weeklyProfiles";
import { getYearsComparisonPath } from "../../routes/yearsComparison";
import AnalysisSkeleton from "../AnalysisSkeleton";

interface AnalyzerLayoutProps {
  children?: React.ReactNode;
}

export default function AnalyzerLayout({ children }: AnalyzerLayoutProps) {
  const analyzerItems = useAnalyzerItems();
  const analysisItems = useAnalysisItems();

  const skeleton = useSkeleton();

  return (
    <Sidebar topNavigation={[analyzerItems, analysisItems]}>
      <BasicLayout skeleton={skeleton}>{children}</BasicLayout>
    </Sidebar>
  );
}

function useAnalyzerItems(): SidebarItem[] {
  const [, solutionId] = useSolution();
  const { pathname } = useLocation();
  const { t } = useTranslation();

  const items: SidebarItem[] = [
    {
      key: "dashboard",
      name: t("Dashboard"),
      href: getAnalyzerPath(solutionId),
      icon: HomeIcon,
      isCurrent: pathname === getAnalyzerPath(solutionId),
    },
  ];

  return items;
}

function useAnalysisItems(): SidebarItem[] {
  const [{ data: solution }, solutionId] = useSolution();

  const minScale = solution ? getMinScale(solution.aggregates) : undefined;

  const { analysisId } = useParams();
  const { pathname, search } = useLocation();
  const { t } = useTranslation();

  if (!analysisId) {
    return [];
  }

  const items: SidebarItem[] = [
    {
      key: "heatmap",
      name: t("Heatmap"),
      href: getAnalysisPath(solutionId, analysisId) + search,
      icon: ((props) => <LuChartBar {...props} />) satisfies CustomIcon,
      isCurrent: pathname === getAnalysisPath(solutionId, analysisId),
      isHidden: !!minScale && compareScales(minScale, ">", "1D"),
      isLoading: !solution,
    },
    {
      key: "overview",
      name: t("Summary statistics"),
      href: getOverviewPath(solutionId, analysisId) + search,
      icon: TableCellsIcon,
      isCurrent: pathname === getOverviewPath(solutionId, analysisId),
    },
    {
      key: "timeSeries",
      name: t("Time series"),
      href: getTimeSeriesPath(solutionId, analysisId) + search,
      icon: ((props) => <LuChartLine {...props} />) satisfies CustomIcon,
      isCurrent: pathname === getTimeSeriesPath(solutionId, analysisId),
    },
    {
      key: "yearsComparison",
      name: t("Years comparison"),
      href: getYearsComparisonPath(solutionId, analysisId) + search,
      icon: ((props) => <LuChartLine {...props} />) satisfies CustomIcon,
      isCurrent: pathname === getYearsComparisonPath(solutionId, analysisId),
      isHidden: !!minScale && compareScales(minScale, ">=", "1Y"),
      isLoading: !solution,
    },
    {
      key: "weeklyProfiles",
      name: t("Weekly profiles"),
      href: getWeeklyProfilesPath(solutionId, analysisId) + search,
      icon: ((props) => <LuChartLine {...props} />) satisfies CustomIcon,
      isCurrent: pathname === getWeeklyProfilesPath(solutionId, analysisId),
      isHidden: !!minScale && compareScales(minScale, ">=", "1D"),
      isLoading: !solution,
    },
    {
      key: "dailyProfiles",
      name: t("Daily profiles"),
      href: getDailyProfilesPath(solutionId, analysisId) + search,
      icon: ((props) => <LuChartLine {...props} />) satisfies CustomIcon,
      isCurrent: pathname === getDailyProfilesPath(solutionId, analysisId),
      isHidden: !!minScale && compareScales(minScale, ">=", "1D"),
      isLoading: !solution,
    },
    {
      key: "analysisSettings",
      name: t("Analysis settings"),
      href: getSettingsPath(solutionId, analysisId) + search,
      icon: Cog6ToothIcon,
      isCurrent: pathname.startsWith(getSettingsPath(solutionId, analysisId)),
    },
  ];

  return items;
}

function useSkeleton(): JSX.Element {
  const { pathname } = useLocation();
  const { solutionId, analysisId } = useParams();

  if (!solutionId) {
    return <BasicSkeleton />;
  }

  if (!analysisId) {
    return <ListPageSkeleton />;
  }

  // analysis settings page
  if (pathname.startsWith(getSettingsPath(solutionId, analysisId))) {
    return <SettingsSkeleton />;
  }

  // analysis chart page
  if (pathname.startsWith(getAnalysisPath(solutionId, analysisId))) {
    return <AnalysisSkeleton pageTitle={null} />;
  }

  return <BasicSkeleton />;
}

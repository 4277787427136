import { toast } from "react-hot-toast";
import { useTranslation } from "react-i18next";

import { useMutation, useQueryClient } from "@tanstack/react-query";

import api, { EndpointOptions, getQueryString } from "../../../api";
import { FilterSetting } from "../../../models/primitives";
import { ImportExportEntity } from "../models/importExport";
import { PaginatedResponse, paginatedResponse } from "../models/response";
import {
  ChartTask,
  ChartTaskZod,
  DataImportConfigTask,
  DataImportConfigTaskZod,
  GeneralSolutionTask,
  GeneralSolutionTaskZod,
  ModelRunTask,
  ModelRunTaskZod,
  Task,
  TaskDetails,
  TaskDetailsZod,
  TaskName,
  TaskNameZod,
  TaskZod,
  progressingTaskStates,
} from "../models/task";
import { CHARTS_API } from "./charts";
import { MODELS_API } from "./models";
import { RUNS_API } from "./runs";

async function getTasks(
  solutionId?: string,
  options: EndpointOptions = {}
): Promise<PaginatedResponse<Task[]>> {
  const path = solutionId ? `/Solutions/${solutionId}/Tasks` : "/Tasks";
  const query = getQueryString(options);
  return paginatedResponse(TaskZod.array()).parse(
    (await api.get(`${path}?${query}`)).data
  );
}

export const tasksQuery = (solutionId?: string, options?: EndpointOptions) => ({
  queryKey: ["tasks", solutionId, ...(options ? [options] : [])],
  queryFn: () => getTasks(solutionId, options),
});

async function getTask(taskId: string): Promise<Partial<Task>> {
  return TaskZod.partial()
    .passthrough()
    .parse((await api.get(`/Tasks/${taskId}`)).data);
}

export const taskQuery = (taskId: string) => ({
  queryKey: ["task", taskId],
  queryFn: () => getTask(taskId),
});

async function getTaskDetails(taskId: string): Promise<TaskDetails> {
  return TaskDetailsZod.parse((await api.get(`/Tasks/${taskId}/Details`)).data);
}

export const taskDetailsQuery = (taskId: string) => ({
  queryKey: ["task", taskId, "details"],
  queryFn: () => getTaskDetails(taskId),
});

async function getRunTasks(
  runConfigId: string | number,
  options: EndpointOptions = {}
): Promise<ModelRunTask[]> {
  const query = getQueryString(options);
  return ModelRunTaskZod.array().parse(
    (await api.get(`${RUNS_API}/${runConfigId}/Tasks?${query}`)).data
  );
}

export const ACTIVE_RUN_TASKS_PREFIX = ["tasks", "RunModel", "active"];

export const activeRunTasksQuery = (runConfigId: string | number) => ({
  queryKey: [...ACTIVE_RUN_TASKS_PREFIX, "run", `${runConfigId}`],
  queryFn: () =>
    getRunTasks(runConfigId, {
      filter: [["task.status", "=", progressingTaskStates]],
    }),
});

async function getModelTasks(
  modelId: string | number,
  options: EndpointOptions = {}
): Promise<ModelRunTask[]> {
  const query = getQueryString(options);
  return ModelRunTaskZod.array().parse(
    (await api.get(`${MODELS_API}/${modelId}/Tasks${query ? "?" + query : ""}`))
      .data
  );
}

export const activeModelTasksQuery = (modelId: string | number) => ({
  queryKey: [...ACTIVE_RUN_TASKS_PREFIX, "model", `${modelId}`],
  queryFn: () =>
    getModelTasks(modelId, {
      filter: [["task.status", "=", progressingTaskStates]],
    }),
});

async function getSolutionRunTasks(
  solutionId: string | number,
  options: EndpointOptions = {}
): Promise<PaginatedResponse<ModelRunTask[]>> {
  const query = getQueryString(options);
  return paginatedResponse(ModelRunTaskZod.array()).parse(
    (await api.get(`/Solutions/${solutionId}/Tasks${query ? "?" + query : ""}`))
      .data
  );
}

export const activeSolutionRunTasksQuery = (solutionId: string | number) => ({
  queryKey: [...ACTIVE_RUN_TASKS_PREFIX, "solution", `${solutionId}`],
  queryFn: () =>
    getSolutionRunTasks(solutionId, {
      filter: [
        ["task.status", "=", progressingTaskStates],
        [
          "taskName",
          "=",
          [TaskNameZod.Enum.RunModel, TaskNameZod.Enum.RunMultipleModels],
        ],
      ],
    }),
});

async function getDataImportConfigTasks(
  solutionId: string,
  options: EndpointOptions = {}
): Promise<PaginatedResponse<DataImportConfigTask[]>> {
  const query = getQueryString(options);
  return paginatedResponse(DataImportConfigTaskZod.array()).parse(
    (await api.get(`/Solutions/${solutionId}/Tasks?${query}`)).data
  );
}

export const activeDataAnalysisTasksQuery = (solutionId: string) => ({
  queryKey: ["tasks", "DataImportAnalysis", "active", "solution", solutionId],
  queryFn: () =>
    getDataImportConfigTasks(solutionId, {
      filter: [
        ["taskName", "=", TaskNameZod.Enum.DataImportAnalysis],
        ["task.status", "=", progressingTaskStates],
      ],
    }),
});

export const activeDataImportTasksQuery = (solutionId: string) => ({
  queryKey: ["tasks", "DataImport", "active", "solution", solutionId],
  queryFn: () =>
    getDataImportConfigTasks(solutionId, {
      filter: [
        ["taskName", "=", TaskNameZod.Enum.DataImport],
        ["task.status", "=", progressingTaskStates],
      ],
    }),
});

export const activeDataImportConfigTasksQuery = (
  solutionId: string,
  dataImportConfigId: string
) => ({
  queryKey: ["tasks", "active", "dataset", dataImportConfigId],
  queryFn: () =>
    getDataImportConfigTasks(solutionId, {
      filter: [
        ["dataImportConfigId", "=", dataImportConfigId],
        ["task.status", "=", progressingTaskStates],
      ],
    }),
});

export const failedOrFinishedDataImportConfigTasksQuery = (
  solutionId: string,
  dataImportConfigId: string
) => ({
  queryKey: ["tasks", "finished", "dataset", dataImportConfigId],
  queryFn: async (): Promise<PaginatedResponse<DataImportConfigTask[]>> =>
    getDataImportConfigTasks(solutionId, {
      filter: [
        ["dataImportConfigId", "=", dataImportConfigId],
        [
          "taskName",
          "=",
          [TaskNameZod.Enum.DataImportAnalysis, TaskNameZod.Enum.DataImport],
        ],
        ["task.status", "=", ["Failed", "Finished", "Canceled"]],
      ],
    }),
});

export const failedOrFinishedFixedModelAssignmentTasksQuery = (
  runConfigId: string | number
) => ({
  queryKey: [
    "tasks",
    "finished",
    "fixedModelAssignment",
    runConfigId.toString(),
  ],
  queryFn: async (): Promise<ModelRunTask[]> =>
    getRunTasks(runConfigId, {
      filter: [
        ["taskName", "=", TaskNameZod.Enum.ModelAutoSelection],
        ["task.status", "=", ["Failed", "Finished", "Canceled"]],
      ],
    }),
});

export const finishedEntityImportTasksQuery = (
  solutionId: string,
  importExportConfigId: string
) => ({
  queryKey: ["tasks", "finished", "importExportConfig", importExportConfigId],
  queryFn: () =>
    getGeneralSolutionTasks(solutionId, {
      filter: [
        ["importExportConfigId", "=", importExportConfigId],
        ["task.status", "=", ["Failed", "Finished", "Canceled"]],
      ],
    }),
});

async function cancelTask(taskId: string) {
  return api.delete(`/Tasks/${taskId}`);
}

export const useCancelTask = () => {
  const queryClient = useQueryClient();
  const { t } = useTranslation();

  return useMutation({
    mutationFn: (taskId: string) => cancelTask(taskId),
    onSuccess: () => {
      toast.success(t("Run is being canceled."));
      queryClient.invalidateQueries({ queryKey: ACTIVE_RUN_TASKS_PREFIX });
      queryClient.invalidateQueries({ queryKey: ["tasks"] });
    },
    onError: () => {
      toast.error(t("Failed to cancel the run. Please try again."));
    },
  });
};

async function getChartTasks(
  chartId: string,
  options: EndpointOptions = {}
): Promise<ChartTask[]> {
  const query = getQueryString(options);
  return ChartTaskZod.array().parse(
    (await api.get(`${CHARTS_API}/${chartId}/Tasks${query ? "?" + query : ""}`))
      .data
  );
}

export const activeChartTasksQuery = (chartId: string) => ({
  queryKey: ["tasks", "Statistics", "active", "chart", chartId],
  queryFn: () =>
    getChartTasks(chartId, {
      filter: [["task.status", "=", progressingTaskStates]],
      sort: [["task.enqueuedTime", "desc"]],
    }),
});

async function getGeneralSolutionTasks(
  solutionId: string | number,
  options: EndpointOptions = {}
): Promise<PaginatedResponse<GeneralSolutionTask[]>> {
  const query = getQueryString(options);
  return paginatedResponse(GeneralSolutionTaskZod.array()).parse(
    (await api.get(`/Solutions/${solutionId}/Tasks${query ? "?" + query : ""}`))
      .data
  );
}

export const activeAggregateCalcTasksQuery = (solutionId: string | number) => ({
  queryKey: [
    "tasks",
    "CalculateAggregates",
    "active",
    "solution",
    `${solutionId}`,
  ],
  queryFn: () =>
    getGeneralSolutionTasks(solutionId, {
      filter: [
        ["task.status", "=", progressingTaskStates],
        ["taskName", "=", "CalculateAggregates"],
      ],
    }),
});

export const failedOrFinishedAggregateTasksQuery = (solutionId: string) => ({
  queryKey: [
    "tasks",
    "CalculateAggregates",
    "failedOrFinished",
    "solution",
    solutionId,
  ],
  queryFn: () =>
    getGeneralSolutionTasks(solutionId, {
      filter: [
        ["task.status", "=", ["Failed", "Finished"]],
        ["taskName", "=", "CalculateAggregates"],
      ],
    }),
});

export const activeOutlierDetectionTasksQuery = (solutionId: string) => ({
  queryKey: ["tasks", "OutlierDetection", "active", "solution", solutionId],
  queryFn: () =>
    getGeneralSolutionTasks(solutionId, {
      filter: [
        ["task.status", "=", progressingTaskStates],
        ["taskName", "=", "OutlierDetection"],
      ],
    }),
});

export const activeExportTasksQuery = (
  solutionId: string,
  entity: `Export${ImportExportEntity}` & TaskName,
  importExportConfigId?: string | number
) => ({
  queryKey: [
    "tasks",
    entity,
    "active",
    "solution",
    solutionId,
    importExportConfigId?.toString(),
  ],
  queryFn: () =>
    getGeneralSolutionTasks(solutionId, {
      filter: [
        ["task.status", "=", progressingTaskStates],
        ["taskName", "=", entity],
        ...(importExportConfigId
          ? [
              [
                "importExportConfigId",
                "=",
                importExportConfigId.toString(),
              ] satisfies FilterSetting,
            ]
          : []),
      ],
    }),
});

export const activeImportTasksQuery = (
  solutionId: string,
  entity: `Import${ImportExportEntity}` & TaskName,
  importExportConfigId?: string | number
) => ({
  queryKey: [
    "tasks",
    entity,
    "active",
    "solution",
    solutionId,
    ...(importExportConfigId ? [importExportConfigId.toString()] : []),
  ],
  queryFn: () =>
    getGeneralSolutionTasks(solutionId, {
      filter: [
        ["task.status", "=", progressingTaskStates],
        ["taskName", "=", entity],
        ...(importExportConfigId
          ? [
              [
                "importExportConfigId",
                "=",
                importExportConfigId.toString(),
              ] satisfies FilterSetting,
            ]
          : []),
      ],
    }),
});

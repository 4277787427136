import React, { type JSX } from "react";
import { useTranslation } from "react-i18next";
import { useLocation } from "react-router";

import {
  CalendarDaysIcon,
  CircleStackIcon,
  Cog6ToothIcon,
  CogIcon,
} from "@heroicons/react/24/outline";

import { HeroIcon } from "../../../../../models/primitives";
import SideMenu from "../../../components/sidemenu/SideMenu";
import SideMenuEntry from "../../../components/sidemenu/SideMenuEntry";
import { InfluencingFactorDetail } from "../../../models/influencingFactor";
import { OutlierPage, OutlierPageZod } from "../../../routes/outliers";

interface OutlierMenuProps {
  outlier: InfluencingFactorDetail | undefined;
}

export default function OutlierMenu(props: OutlierMenuProps): JSX.Element {
  const { outlier } = props;
  const pages = usePages(outlier);
  const page = useCurrentPage();

  return (
    <SideMenu>
      {pages.map((item) => (
        <SideMenuEntry
          key={item.id}
          id={item.id}
          name={item.name}
          href={`./${item.id}`}
          icon={item.icon}
          active={item.id === page}
          disabled={
            item.disabled ||
            !outlier ||
            (outlier.source === "Automatic" && item.id === "runConfigs")
          }
        />
      ))}
    </SideMenu>
  );
}

interface NavItem {
  id: OutlierPage;
  name: string;
  icon: HeroIcon;
  disabled?: boolean;
}

const usePages = (outlier: InfluencingFactorDetail | undefined): NavItem[] => {
  const { t } = useTranslation();

  return [
    { id: "general", name: t("General Settings"), icon: Cog6ToothIcon },
    {
      id: "ranges",
      name: t("Ranges"),
      icon: CalendarDaysIcon,
    },
    {
      id: "runConfigs",
      name: t("Scenarios"),
      icon: CogIcon,
      disabled: !outlier || outlier.appliesToSolution,
    },
    {
      id: "dataSegments",
      name: t("Observables & Planning Areas"),
      icon: CircleStackIcon,
      disabled: !outlier || outlier.appliesToSolution,
    },
  ];
};

export const useCurrentPage = (): OutlierPage => {
  const location = useLocation();
  const paths = location.pathname.split("/");

  return OutlierPageZod.catch(OutlierPageZod.Enum.general).parse(
    paths[paths.length - 1]
  );
};

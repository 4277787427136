import React from "react";
import { RouteObject } from "react-router";

import lazyWithRetry from "../../../services/lazyWithRetry";
import { getBusinessMapPath } from "./businessMap";

const Variables = lazyWithRetry(() => import("../pages/Variables/Variables"));
const VariableDetail = lazyWithRetry(
  () => import("../pages/VariableDetail/VariableDetail")
);

const VARIABLES_PATH = "variables";

export const variablesRoute: RouteObject = {
  path: VARIABLES_PATH,
  children: [
    { index: true, element: <Variables /> },
    { path: ":demandVariableId", element: <VariableDetail /> },
  ],
};

export function getVariablesPath(
  solutionId: string | number,
  businessMapId: string | number
): string {
  return `${getBusinessMapPath(solutionId, businessMapId)}/${VARIABLES_PATH}`;
}

export function getVariableDetailPath(
  solutionId: string | number,
  businessMapId: string | number,
  demandVariableId: string | number
): string {
  return `${getVariablesPath(solutionId, businessMapId)}/${demandVariableId}`;
}

import React, { type JSX } from "react";
import { useTranslation } from "react-i18next";

import Select from "../../../../../components/form/Select";
import { ImportExportZone } from "../../../../PrognosAI/models/dataImportConfig";
import { SolutionDetail } from "../../../../PrognosAI/models/solution";

interface TimestampZonePickerProps {
  id: string;
  solution: SolutionDetail | undefined;
  value: ImportExportZone;
  onChange(value: ImportExportZone): void;
  disabled?: boolean;
  label?: string;
}

export default function TimestampZonePicker(
  props: TimestampZonePickerProps
): JSX.Element {
  const { id, solution, value, onChange } = props;
  const { disabled, label } = props;

  const { t } = useTranslation();

  const timezoneOptions: { key: ImportExportZone; label: string }[] = [
    {
      key: "Local",
      label: solution?.timezone
        ? `${t("Project timezone")} (${solution.timezone})`
        : t("Project timezone"),
    },
    { key: "Utc", label: "UTC" },
  ];
  const selectedOption = timezoneOptions.find((o) => o.key === value) ?? null;

  return (
    <Select
      id={id}
      label={label}
      options={timezoneOptions}
      value={selectedOption}
      disabled={disabled}
      onChange={({ key }) => onChange(key)}
    />
  );
}
